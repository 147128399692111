<template>
  <div class="league-star">
    <div class="title">
      <slot>
        答题之星
      </slot>
    </div>
    <div class="border-outer">
      <div class="border-inner">
        <div v-if="studentList.length === 0" class="border-none">
          暂无学生哦~
        </div>

        <div v-else class="border-max3">
          <div v-for="(i, k) in studentList" :key="k" class="border-max3__item">
            <div class="avatar">
              <dw-avatar :img="i.avatar" :sex="i.sex" />
            </div>
            <div class="name">
              {{i.studentName}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DwAvatar from "@/components/system/DwAvatar";
export default {
  name: "LeagueStar",
  components: {DwAvatar},
  props:{
    studentList: {
      type: Array,
      required: false,
    }
  },
  setup(){

  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="less" scoped>
.league-star {
  width: 550px;
  height: 259px;
  position: relative;
  .title{
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-60%);
    font-size: 30px;
    font-weight: bold;
    color: #FFFFFF;
    background: linear-gradient(139deg, #FAD458 0%, #FF9000 100%);
    border-radius: 32px;
    border: 3px solid #FFFFFF;
    padding: 3px 40px;
  }
  .border-outer{
    width: 100%;
    height: 100%;
    background-color: #FFF;
    border-radius: 42px;
    box-shadow: 0px 2px 4px #B87903;
    padding: 10px;
    .border-inner{
      width: 100%;
      height: 100%;
      border: 3px dashed #FBE2AF;
      border-radius: 42px;
      .border-none{
        width: 100%;
        height: 100%;
        font-size: 30px;
        font-weight: bold;
        color: #41464A;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .border-max3{
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 20px;
        height: 100%;
        .border-max3__item{
          .avatar{
            width: 152px;
            height: 152px;
            background: url("~@/assets/image/timetable/ic_touxbg@2x.png") no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .name{
            font-size: 26px;
            font-weight: bold;
            color: #41464A;
            text-align: center;
            margin-top: 5px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
